@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.home{
   margin: 0 auto;
   display: grid;
   padding: 0px;
   grid-template-columns: 1fr 1fr 1fr 1fr;
   grid-auto-rows: minmax(180px, auto);   
   border-radius: 25px;

   @include xl{
      width: 85%;
  }

  @include md{
      width: 100%;
  }

  .home-box-top{
      padding: 15px;
      margin-bottom: 0px;
   }

  .home-box{
      padding: 20px;
      margin: 10px;
   }

   .home-box1{
      grid-column: span 4;
      grid-row: span 2;
      border-bottom: 3px solid $secondary-bg;
   }

   .home-box2{
      align-items: center;
      justify-self: center; 
      grid-column: span 4;
      grid-row: span 2;
   }

   .home-box3{
      grid-column: span 4;
      grid-row: span 4;
      border-top: 3px solid $secondary-bg;

      @include xs{
         display: none;
     }
 
   }
   
}