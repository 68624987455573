@import "../../../styles/variables.scss";
@import "../../../styles/responsive.scss";

.header{
    width: 100%;
    padding: 20px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid $secondary-bg;
}

.logo-header{
    display: flex;
    align-items: center;
    font-weight: bold;
    gap: 10px;

    .header-img img{
        border-radius: 15px;
    }
}

.icons{
    display: flex;
    align-items: center;
    gap: 20px;

    .menu-modal{        
        @include md {
            display: flex;
        }
        
        display: none;
        
        .menu-icon{
            cursor: pointer;

            &:hover{
                color: $secondary-bg;
            }
        }
    }

    .menu-modal-nav{       
        @include md {
            display: none;
        }
        
        display: flex;
        gap: 10px;

        .menu-link{
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 10px;
            border-radius: 5px;
            background-color: $soft-bg;
            
            &:hover{
                background-color: $secondary-bg;
            }
        } 
        
        .login-btn{
            background-color: $primary-bg;
            color: $main-bg;

            &:hover{
                background-color: $secondary-bg;
            }
        }
    } 
}

.user{
    gap: 10px;

    @include sm {
        display: none;
    }
}

