@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.form-login {
    width: 50%;
    margin: 0 auto;    
    padding: 10px;
    border: 3px solid $secondary-bg;
    border-radius: 10px;
    background-color: $dark-bg;

    @include xl{
        width: 75%;
    }

    @include md{
        width: 100%;
    }
}

.login-page{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 75vh;

    .heading{
        font-size: 24px;
    }

    .register-link{
        color: rgb(26, 172, 229);
        text-decoration: underline;
    }
}
