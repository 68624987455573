@import "../../styles/variables.scss";

.noPage{
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center; 
    height: 75vh;
    
    .noPage-box{
        border: 5px solid $secondary-bg;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center; 

        .error-code {
            font-size: 62px;
            margin-bottom: 20px;

            img{
              max-width: 100%;
              max-height: 100%;
              width: auto;
              height: auto;
            }
          }
          
          .error-message {
            text-align: center;
          
            h3 {
              margin-bottom: 20px;
            }
          
            p {
              margin-bottom: 20px;
            }

            button {
                margin: 0 auto;
            }
          }
    }

}