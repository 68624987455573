@import "../../../styles/variables.scss";
@import "../../../styles/responsive.scss";

.hero-section{
    width: 100%;
    margin: 0 auto;
    display: grid;
    padding: 5px;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: minmax(360px, auto);   
    padding: 5px;

   @include xl{
       width: 100%;
       grid-template-columns: 1fr;
   }

   @include sm{
    border-bottom-right-radius: 150px;
    border-top-left-radius: 150px;
     
   }
   
   .hero-box{
       padding: 20px;
       margin: 10px;
       display: flex; 
       flex-direction: column; 
       justify-content: center; 
       align-items: center; 
       text-align: center;
    }
 
    .hero-box1{
       grid-column: span 1;
       grid-row: span 1;
       border-right: solid 3px $secondary-bg;

       @include xl{
        border-right: none;
        border-bottom: solid 3px $secondary-bg;
       }

       @include sm{
        border-bottom: none;
        padding: 0px;
        margin: 0px;
       }
       
       .hero-text-container {
        
            h1, h2 {
                margin-bottom: 10px;
            }

            @include sm{
                
                
                p {
                    font-size: 0.8em;
                }
            }
        
        }

       .button-container{
            display: flex;
            justify-content: center;
            gap: 20px;
            margin-top: 15px;

            .hero-btn{
                padding: 10px;
                gap: 5px;
                width: 95px;      
                justify-content: center;
            }
       }

       img{
            margin-bottom: 10px; 
            border-radius: 15px;                                
       }
    }
 
    .hero-box2{
       grid-column: span 1;
       grid-row: span 1;

        @include sm{
            display: none;
        }
        
       img{
            object-fit: contain;
            width: 100%;
            height: 100%;              
       }
    }
}