.box-pie-chart{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .pie-chart{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        
        .container-pie-chart{
            width: auto;
            height: auto;
        }
    }

    .options{
        display: flex;
        justify-content: space-between;
        gap: 10px;
        font-size: 14px;

        .option {
            display: flex;
            flex-direction: column;
            gap: 10px;
            align-items: center;

            .option-title{
                display: flex;
                gap: 10px;
                align-items: center;

                .dot{
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                }
            }
        }
    }

}

