@import "../../../styles/variables.scss";
@import "../../../styles/responsive.scss";

.home-features{
    h1{
        text-align: center;        
    }
    
    .features-section{    
        margin: 0 auto;
        display: grid;
        padding: 5px;
        grid-template-columns: 1fr 1fr 1fr;
        grid-auto-rows: minmax(130px, auto);   

        @include md{
            grid-template-columns: 1fr;
        }
        
        .features-box{
            padding: 20px;
            border-radius: 15px;
            border: 5px solid $secondary-bg;
            margin: 10px;
            display: flex; 
            flex-direction: column; 
            justify-content: center; 
            align-items: center; 
            text-align: center;
            background-color: $dark-bg;
            opacity: 0;
            transform: translateY(20px);   
            
            &.visible {
                opacity: 1;
                transform: translateY(0);
                transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
            }

            &:hover{
                background-color: $main-bg;
            }

            .feature-content-icon{
                margin-bottom: 10px;
                font-size: x-large;
                border: 5px solid $soft-color;
                border-radius: 50%;
                padding: 10px;
                align-content: center;
                vertical-align: middle;
                color: $secondary-bg;
            }
        }
    }
}

