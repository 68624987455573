.player-table{
    width: 100%;
    
    .player-grid{
        background-color: #121212;
        padding: 20px;

        .MuiDataGrid-toolbarContainer{
            flex-direction: row-reverse;
        }

        .status-icon{
            display: flex;
            justify-content: center;
            align-items: center; 
            font-size: x-large;
        }

       .action-icons{
            display: flex;
            gap: 10px;
            justify-content: center;
            align-items: center; 
            font-size: x-large;

            .player-edit-icon{
                color: cadetblue;
                cursor: pointer;
            }

            .player-rmFee-icon{
                cursor: pointer;
            }
            
            .player-delete-icon{
                color: red;
                cursor: pointer;
            }
       }
    }
}