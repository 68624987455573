@import "../../../styles/variables.scss";
@import "../../../styles/responsive.scss";

.footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    border-top: 2px solid $secondary-bg;

    @include md{           
        flex-direction: column;
    }

    @include sm{            
        flex-direction: column;
    }

    ul{
        list-style-type: none;

        li{
            padding: 5px;

            h3{
                text-align: center;
                margin-top: 15px;
            }
        }
    }
}

.link-footer{
    img{
        border-radius: 15px;
    }
    
    &:hover {
        color: $secondary-bg;
    }
}



.footer-icon{
    font-size: x-large;
    
}

.logo-footer{
    div{
        display: flex;
        align-items: center;
        font-weight: bold;
        gap: 10px;    
    } 

    .social-media{
        @include md{ 
            display: flex; 
            justify-content: center;          
        }
    }
}
