@import "../../styles/variables.scss";

// Menu Modal
.customMenuModal {
    background: $dark-bg;
    color: $main-color;
    max-width: 400;
    width: 60%;
}

.customMenuModal h2{
    text-align: center;
    margin-bottom: 20px;
}

.menu-item-modal{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
    text-align: center;
    align-items: center;    

    .menu-title-modal{
        font-size: 12px;
        font-weight: 200;
        color: $main-color;          
    }

    .menu-link-modal{
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 10px;
        border-radius: 5px;
        background-color: $soft-bg;

        &:hover{
            background-color: $secondary-bg;
        }  
    }
}