@import "../../../styles/variables.scss";
@import "../../../styles/responsive.scss";

.home-demo{
    h1{
        text-align: center;
    }

    .demo-section{
        width: 100%;
        margin: 0 auto;
        display: grid;
        padding: 5px;
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: minmax(100px, auto);   
        
        @include xl{
        width: 75%;
        }
    
        @include xl{
            width: 100%;
            grid-template-columns: 1fr;
        }

        .demo-text{
            border-bottom-right-radius: 100px;
            border-top-left-radius: 100px;
        }

        .demo-img{
            border-bottom-right-radius: 200px;
            border-top-left-radius: 200px;
        }
        
        .demo-box{
            padding: 20px;        
            margin: 10px;
            display: flex; 
            flex-direction: column; 
            justify-content: center; 
            align-items: center; 
            text-align: center;
            background-color: $dark-bg;
            opacity: 0; 
            transform: translateY(50px);

            &:hover{
                background-color: $main-bg;
            }

            &.visible {
                opacity: 1; 
                transform: translateY(0);
                transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
            }

            p{
                font-size: 1.25rem;
                width: 75%;
            }

            @include xl {
                p{
                    width: 100%;
                }
            }
        }

        img{
            object-fit: contain;
            width: 100%;
            height: 100%;

            @include xl{
               border: solid 2px $secondary-bg; 
            }
            
        }
    }
}