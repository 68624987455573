@import "../../../styles/variables.scss";
@import "../../../styles/responsive.scss";

.assignment{
    display: grid;
    padding: 0px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: minmax(60px, auto);

    @include sm{
        grid-auto-rows: minmax(40px, auto);
        grid-template-columns: 1fr;
    }

    .assignment-box{
        padding: 10px;
        border-radius: 10px;
        border: 2px solid $soft-bg;
        margin: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        span, h4{
            margin-bottom: 5px;
        }

        select {
            width: 50%;
        }
    }

    .box-assign-heading{
        grid-column: span 3;
        grid-row: span 1;
        margin: 0px;
        padding: 0px;
        border: none;
       
        @include sm{
            grid-column: span 1;
            grid-row: span 2;
        }
    }

    .box-player{
        grid-column: span 2;
        grid-row: span 1;

        @include sm{
            grid-column: span 1;
            grid-row: span 2;
        }
    }

    .box-penalty{
        grid-column: span 2;
        grid-row: span 1;

        @include sm{
            grid-column: span 1;
            grid-row: span 2;
        }
    }

    .box-note{
        grid-column: span 2;
        grid-row: span 1;

        @include sm{
            grid-column: span 1;
            grid-row: span 2;
        }
    }

    .box-assign{
        grid-column: span 1;
        grid-row: span 3;

        button{
            width: 100%;
            display: flex; 
            align-items: center; 
            justify-content: center;
            padding: 7px;
            background-color: $primary-bg;

            &:hover{
                background-color: $secondary-bg;
            }
        }

        @include sm{
            grid-column: span 1;
            grid-row: span 2;
            display: none;
        }
    }

    .box-assign-sm{
        display: none;

        button{
            width: 50%;
            display: flex; 
            align-items: center; 
            justify-content: center;
        }

        @include sm{
            grid-column: span 1;
            grid-row: span 2;
            display: flex;
        }
    }

}