@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.menu{
    padding-top: 10px;
    .menu-item{
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 20px;

        .menu-title{
            font-size: 12px;
            font-weight: 200;
            color: $primary-bg;          
        }

        .menu-link{
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 10px;
            border-radius: 5px;
            background-color: $soft-bg;

            &:hover{
                border: 3px solid $secondary-bg;
            }

            span{             
                @include lg{
                    display: none;
                }
                @include md{
                    display: flex;
                }
                @include sm{
                    display: flex;
                }
            }
        }
    }
}
