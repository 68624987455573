@import "../../../styles/variables.scss";

.box-statistic{
    display: flex;
    height: 100%;
    justify-content: center;

    .box-info{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        
        .box-title{
            display: flex;
            align-items: center;
            gap: 10px;
        }

        .box-link:hover{
            color: $secondary-bg
        }
    }
}