.manage-players{
    margin-top: 15px;
    margin-bottom: 15px;

    .player-info{
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 20px;
        flex-wrap: wrap;
        word-wrap: break-word;

        button{
            padding: 10px;
            cursor: pointer;
            display: flex; 
            align-items: center; 
            justify-content: center;
        }
    }
}