@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.dashboard{
    display: grid;
    padding: 20px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-rows: minmax(130px, auto);

    @include xl{
        grid-template-columns: 1fr 1fr;
    }

    @include lg{
        grid-template-columns: 1fr 1fr;
    }

    @include md{
        grid-template-columns: 1fr 1fr;
    }

    @include sm{
        grid-template-columns: 1fr;
        margin: 0px;
        padding: 0px;
    }

    .dashboard-box{
        padding: 20px;
        border-radius: 10px;
        border: 2px solid $secondary-bg;
        margin: 10px;
    }

    .box1{
        grid-column: span 1;
        grid-row: span 3;
    }

    .box4{
        grid-column: span 1;
        grid-row: span 3;

        @include sm{
            display: none;
        }
    }

    .box7{
        grid-column: span 2;
        grid-row: span 1;
    
        @include sm{            
            grid-column: span 1;
        }
    }
}

