
@import "./variables.scss";
@import "./responsive.scss";

// Main
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

.main{  
  font-family: "Orbitron", sans-serif;
  font-weight: 400;
  font-style: normal;
  background-color: $main-bg;
  color: $main-color;
  
}

.container {
  display: flex;
}

// Menu
.menuContainer {
  width: 250px;
  padding: 5px 20px;
  border-right: 2px solid $secondary-bg;

  @include lg{
    width: max-content;
  }
  
  @include md{
    display: none;
  }
    
}

.contentContainer{
  width: 100%;
  padding: 5px 20px;
  min-height: 75vh;
}

.content-heading{
  margin-top: 20px;
  text-align: center;
}

// Modal
.customOverlay {
  background: rgba(18, 18, 19, 0.7);
}

.customModal {
  background: $dark-bg;
  color: $main-color;
  max-width: 400px;
  width: 100%;
}

.customModal h2{
  text-align: center;
  margin-bottom: 10px;
}

.closeBtn{
  background: $main-color;
}

// Modal Form and Login/Register Form
.form {
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  border: 3px solid $secondary-bg;
  border-radius: 10px;
}
  
.form-group {
  margin-bottom: 10px;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid $main-color;
  border-radius: 5px;
  margin-bottom: 10px;
  font-family: inherit;
  color: $soft-color;
  background-color: $soft-bg;
}

.form-group label {
  text-align: left;
  display: block;
  margin: 0 0 5px 3px;
}

.form-group-checkbox-container {
  margin-bottom: 25px;
}

.membership-note{   
  margin-top: 15px;  
  font-size: small;
}

.form-group-checkbox {
  display: flex;
  align-items: center;
  justify-content: center; 
}

.form-group-checkbox input {
  margin-right: 8px; 
}

.heading{
  text-align: center;
  margin-bottom: 20px;
}

.toggle-checkbox {
  position: relative;
  appearance: none;
  width: 50px;
  height: 25px;
  border-radius: 25px;
  background-color: $main-color;
  outline: none;
  cursor: pointer;
}

.toggle-checkbox::before {
  content: '';
  position: absolute;
  top: 1px;
  left: 1px;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background-color: #2c3333;
  transition: 0.3s;
}

.toggle-checkbox:checked::before {
  left: 25px;
  background-color: $secondary-bg;
}

.form-group button{
  display: block;
  margin: 0 auto; 
  width: 75%; 
  padding: 5px;
  background-color: $primary-bg;
  border-radius: 25px;
  cursor: pointer;
  padding: 10px;

  @include md{
    width: 100%;
  }

  &:hover{
    background-color: $secondary-bg;
  }
}

// Buttons
.btn{
  background-color: $soft-color;
}

button{
  display: flex;
  padding: 5px;
  background-color: $primary-bg;
  border-radius: 25px;
  cursor: pointer;
  text-align: center;

  &:hover{
    background-color: $secondary-bg;
  }
}

// Icon
.icon{
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}

// Spinner
.loading-spinner-container {
  align-items: center;  
  display: flex;position: fixed;  
  right: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5000;
  bottom: 0;
  left: 0;
  justify-content: center;
}

.loading-spinner {
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
  border-color: #000 transparent #555 transparent;
  width: 64px;
  height: 64px;
  border: 8px solid;
  
}

// Waves
.layout-wave-container {
  height: 500px; 
  width: 100%;
  position: absolute;
  overflow-x: hidden;

  @include xl{
    height: 405px;
  }

  @include md{
    height: 340px;
  }

  @include sm{
    height: 194px;
  }

  .layout-wave {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3C!-- Created with Vectornator (http://vectornator.io/) --%3E%3Csvg height='100%' stroke-miterlimit='10' style='fill-rule:nonzero;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;' version='1.1' viewBox='0 0 1500 550' width='100%' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:vectornator='http://vectornator.io' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs/%3E%3CclipPath id='ArtboardFrame'%3E%3Crect height='550' width='1500' x='0' y='0'/%3E%3C/clipPath%3E%3Cg clip-path='url(%23ArtboardFrame)' id='Layer-1' vectornator:layerName='Layer 1'%3E%3Cpath d='M-73.2318 275C-73.2318 275-44.2958 367-2.26564 367C50.9501 367 85.4692 341.242 129.899 293.788C173.824 247.045 217.749 207.945 262.178 236.915C306.103 264.818 350.028 360.793 394.458 407.536C438.282 454.99 482.307 454.99 526.737 388.696C570.46 321.692 614.587 190.172 659.017 161.201C702.639 133.298 746.866 207.945 790.286 236.915C834.817 264.818 879.146 247.045 922.566 284.369C966.995 321.692 1011.43 417.666 1054.85 464.409C1099.17 511.864 1143.7 511.864 1187.12 464.409C1231.35 417.666 1274.97 321.692 1319.4 293.788C1363.53 264.818 1374.16 268.897 1403.94 299.488C1428.75 324.965 1459.12 367 1500 367C1561.63 367 1500 0 1500 0L1429.67 0C1407.66 0 1363.83 0 1319.4 0C1275.48 0 1231.55 0 1187.12 0C1143.2 0 1099.27 0 1054.85 0C1011.02 0 966.995 0 922.566 0C878.843 0 834.716 0 790.286 0C746.664 0 702.437 0 659.017 0C614.486 0 570.157 0 526.737 0C482.307 0 437.878 0 394.458 0C350.129 0 305.598 0 262.178 0C217.951 0 174.329 0 129.899 0C85.7721 0 42.0492 0 19.8344 0L-2.38048 0' fill='%231cb4f0' fill-rule='nonzero' opacity='1' stroke='none' vectornator:layerName='path 1'/%3E%3C/g%3E%3C/svg%3E%0A");
    position: absolute;
    width: 200%;
    height: 100%;
    animation: wave 40s -3s linear infinite;
    transform: translate3d(0, 0, 0);
    opacity: 0.8;
  }

  .layout-wave:nth-of-type(2) {
    animation: wave 48s linear reverse infinite;
    opacity: 0.5;
  }

  .layout-wave:nth-of-type(3) {
    animation: wave 50s -1s linear infinite;
    opacity: 0.5;
  }
}

@keyframes wave {
    0% {transform: translateX(0);}
    50% {transform: translateX(-50%);}
    0% {transform: translateX(0);}
}

.overlay{  
  z-index: 1;
}