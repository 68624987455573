@import "../../../styles/variables.scss";
@import "../../../styles/responsive.scss";

.top-fees{
    h1{
        margin-bottom: 10px;
    }
    p {
        margin-bottom: 25px;;
    }
    .player-list{
        .player-list-item{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 30px;

            .player-item{
                display: flex;
                gap: 20px;

                .player-number{
                    border: 3px solid $soft-bg;
                    border-radius: 50%;
                    gap: 5px;
                    width: 50px;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .player-text{
                    display: flex;
                    flex-direction: column;
                    gap: 5px;

                    .player-surname{
                        font-size: 15px;
                        font-weight: 500;
                    }

                    .player-name{
                        font-size: 13px;
                        margin-left: 25px;
                    }
                }
            }
            .player-fee{
                font-weight: bold;
            }
        }
    }
}