.membership-table{
    width: 100%;
    
    .membership-grid{
        background-color: #121212;
        padding: 20px;

        .MuiDataGrid-toolbarContainer{
            flex-direction: row-reverse;
        }

        .status-icon{
            display: flex;
            justify-content: center;
            align-items: center; 
            font-size: x-large;

            .checked-icon{
                color: green;
            }

            .not-checked-icon{
                color: red;
            }
        }

       .action-icons{
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: x-large;
            gap: 15px;

            .membership-edit-icon{
                color: cadetblue;
                cursor: pointer;
            }
            
            .membership-delete-icon{
                color: red;
                cursor: pointer;
            }
       }
    }
}